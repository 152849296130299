<template>
	<check-for-missing-integrations :integrations="['LinkedIn']">
		<landing-page slot="no-integrations"
					  image="/images/integrations/cta-messages/linkedin.png"
					  :headline="$t('linkedIn.ads.onboarding.headline')"
					  :description="$t('linkedIn.ads.onboarding.description')">
			<hr />

			<integration
				name="LinkedIn Ads"
				service="linked-in"
				:border="true"
				logo="/images/onboarding/linkedin.svg"
				:headline="$t('integrations.list.linkedIn.headline')"
				:description="$t('integrations.list.linkedIn.description')"
			/>

			<hr />
		</landing-page>

		<div class="page">
			<view-selector :views="views"
						   :view="view"
						   @view="setView"
			/>

			<div>
				<div v-if="showComparison"
					 class="compare-to">
					<div class="compare-to-label">{{ $t('linkedIn.ads.compare.label') }}</div>

					<div class="options">
						<div class="option"
							 @click="compareTo = 'year'"
							 :class="{ active: compareTo === 'year' }">
							<div class="option-label">{{ $t('linkedIn.ads.compare.year') }}</div>
						</div>

						<div class="option"
							 @click="compareTo = 'period'"
							 :class="{ active: compareTo === 'period' }">
							<div class="option-label">{{ $t('linkedIn.ads.compare.period') }}</div>
						</div>
					</div>
				</div>

				<networks :networks="networks"
						  :view="view"
						  :views="views"
						  :compare-to="compareTo"
						  :currency="currency"
				/>
			</div>

			<campaigns-table :network="network"
							 :networks="networks"
							 :view="view"
							 :views="views"
							 :currency="currency"
							 @view="setView"
			/>
		</div>
	</check-for-missing-integrations>
</template>

<style lang="scss" scoped>
.page {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0 15px;

	div.compare-to {
		display: flex;
		align-items: center;
		gap: 10px;
		margin: 0 0 10px 0;

		> div.compare-to-label {
			color: #000;
			font-weight: 500;
			font-size: 13px;
		}

		> div.options {
			flex: 1;

			display: flex;
			flex-wrap: wrap;
			gap: 6px;

			> div.option {
				&.active {
					> div.option-label {
						color: #000;
						border-color: #000;
					}
				}

				> div.option-label {
					cursor: pointer;
					user-select: none;

					color: #5b5b5b;
					font-weight: 400;
					padding: 4px 12px;
					border-radius: 100px;
					background-color: #fff;
					border: 2px solid #eee;

					font-size: 13px;

					&:hover {
						color: #000;
					}
				}
			}
		}
	}
}
</style>

<script>
import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import marketingMixin from '@/mixins/marketingMixin'

import Networks from '@/app/linkedin/pages/components/Networks'
import CampaignsTable from '@/app/linkedin/pages/components/campaigns-table/CampaignsTable'
import CheckForMissingIntegrations from "@/app/integrations/components/CheckForMissingIntegrations";
import LandingPage from "@/app/onboarding/components/LandingPage";
import Integration from '@/app/onboarding/components/integrations/Integration'
import ViewSelector from '@/app/linkedin/pages/components/ViewSelector'
import {mapGetters} from 'vuex'

import LinkedInService from '@/services/linkedin/LinkedInService'

export default {
	mixins: [
		datepickerMixin,
		marketingMixin
	],

	data: () => ({
		view: 'default',
		network: 'linkedin',
		networkData: {},
		compareTo: 'year',
		currency: null,
		networks: {
			linkedin: {
				icon: 'fab fa-linked-in fa-fw',
				label: 'LinkedIn',
			}
		}
	}),

	computed: {
		showComparison() {
			return this.$store.getters['datepicker/getShowComparison']
		},

		views() {
			return {
				default: [
					{
						icon: 'far fa-eye',
						field: 'impressions',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.impressions')
					},
					{
						icon: 'far fa-eye',
						field: 'approximateMemberReach',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.reach')
					},
					{
						icon: 'far fa-heart',
						field: 'calculation',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.socialActions'),
						calculation: (metrics) => {
							const likes = Number(metrics.likes || 0)
							const shares = Number(metrics.shares || 0)
							const comments = Number(metrics.comments || 0)

							return likes + shares + comments
						}
					},
					{
						icon: 'far fa-globe',
						field: 'landingPageClicks',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.clickToWebsite')
					},
					{
						icon: 'far fa-coins',
						field: 'calculation',
						type: 'currency',
						label: this.$t('linkedIn.ads.metrics.cpc'),
						calculation: metrics => {
							const spend = Number(metrics.costInLocalCurrency || 0)
							const clicks = Number(metrics.landingPageClicks || 0)

							return spend > 0
								? spend / clicks
								: 0
						}
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'costInLocalCurrency',
						type: 'currency',
						label: this.$t('linkedIn.ads.metrics.cpc')
					}
				],
				branding: [
					{
						icon: 'far fa-eye',
						field: 'approximateMemberReach',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.reach')
					},
					{
						icon: 'far fa-eye',
						field: 'impressions',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.impressions')
					},
					{
						icon: 'far fa-dollar',
						field: 'calculation',
						type: 'currency',
						label: this.$t('linkedIn.ads.metrics.cpm'),
						calculation: metrics => {
							const spend = Number(metrics.costInLocalCurrency || 0)
							const impressions = Number(metrics.impressions || 0)

							return impressions > 0
								? spend / impressions * 1000
								: 0
						}
					},
					{
						icon: 'far fa-wave-pulse',
						field: 'calculation',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.frequency'),
						calculation: metrics => {
							const impressions = Number(metrics.impressions || 0)
							const reach = Number(metrics.approximateMemberReach || 0)

							return reach > 0
								? Number(impressions / reach).toFixed(1)
								: 0
						}
					},
					{
						icon: 'far fa-play',
						field: 'videoViews',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.videoPlays')
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'costInLocalCurrency',
						type: 'currency',
						label: this.$t('linkedIn.ads.metrics.spend')
					}
				],
				video: [
					{
						icon: 'far fa-play',
						field: 'videoViews',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.videoPlays')
					},
					{
						icon: 'far fa-eye',
						field: 'approximateMemberReach',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.reach')
					},
					{
						icon: 'far fa-check',
						field: 'videoCompletions',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.fullVideoPlays')
					},
					{
						icon: 'far fa-percent',
						field: 'calculation',
						type: 'percentage',
						label: this.$t('linkedIn.ads.metrics.fullVideoRate'),
						calculation: metrics => {
							const videoCompletions = Number(metrics.videoCompletions || 0)
							const videoStarts = Number(metrics.videoStarts || 0)

							return videoStarts > 0
								? videoCompletions / videoStarts * 100
								: 0
						}
					},
					{
						icon: 'far fa-dollar',
						field: 'calculation',
						type: 'currency',
						label: this.$t('linkedIn.ads.metrics.cpm'),
						calculation: metrics => {
							const spend = Number(metrics.costInLocalCurrency || 0)
							const videoCompletions = Number(metrics.videoCompletions || 0)

							return videoCompletions > 0
								? spend / videoCompletions
								: 0
						}
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'costInLocalCurrency',
						type: 'currency',
						label: this.$t('linkedIn.ads.metrics.spend')
					}
				],
				conversions: [
					{
						icon: 'far fa-filter',
						field: 'externalWebsiteConversions',
						type: 'number',
						label: this.$t('linkedIn.ads.metrics.conversions')
					},
					{
						icon: 'far fa-coins',
						field: 'calculation',
						type: 'currency',
						label: this.$t('linkedIn.ads.metrics.costPerConversion'),
						calculation: metrics => {
							const spend = Number(metrics.costInLocalCurrency || 0)
							const conversions = Number(metrics.externalWebsiteConversions || 0)

							return conversions > 0
								? spend / conversions
								: 0
						}
					},
					{
						icon: 'far fa-percent',
						field: 'calculation',
						type: 'percentage',
						label: this.$t('linkedIn.ads.metrics.conversionRate'),
						calculation: metrics => {
							const conversions = Number(metrics.externalWebsiteConversions || 0)
							const clicks = Number(metrics.clicks || 0)

							return clicks > 0
								? conversions / clicks * 100
								: 0
						}
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'costInLocalCurrency',
						type: 'currency',
						label: this.$t('linkedIn.ads.metrics.spend')
					}
				]
			}
		},

		...mapGetters("customer", {
			customer: "getCustomer",
		}),

		...mapGetters('ghost', {
			isDemo: 'isDemo',
		}),

		integrationId() {
			if (this.isDemo) {
				return 'demo-id'
			}

			const integrations = this.customer.integrations

			const match = integrations.find(integration => integration.service.name === "LinkedIn")

			return !! match
				? match.id
				: null
		}
	},

	mounted() {
		this.pushFilters()
		this.loadCurrency()

		this.$store.commit("datepicker/setShowComparisonToggleButton", true)
	},

	destroyed() {
		this.$store.commit("datepicker/setShowComparisonToggleButton", false)
	},

	methods: {
		loadCurrency() {
			LinkedInService.currency(
				(response) => {
					this.currency = response.data
				},
				() => {}
			)
		},

		setView(view) {
			this.view = view
		},

		pushFilters() {
			eventHub.$emit("topbar.filterGroups.push", {
				title: "Netværk",
				slug: "search-network",
				metrics: Object.keys(this.networks).map(key => ({
					label: this.networks[key].label,
					slug: key,
					selected: key === 'linkedin'
				}))
			});

			eventHub.$on(
				'topbar.filterGroups.search-network.metricsChanged',
				(payload) => {
					Object.keys(payload.metrics).forEach((metricKey) => {
						const metricValue = payload.metrics[metricKey];

						if (!metricValue) {
							return;
						}

						this.network = metricKey
					});
				}
			);
		}
	},

	components: {
		ViewSelector,
		Integration,
		LandingPage,
		CheckForMissingIntegrations,
		Networks,
		CampaignsTable
	}
}
</script>
