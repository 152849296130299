<template>
	<div class="networks"
		 ref="wrapper">
		<button v-if="canGoPrevious"
				@click="previous"
				type="button"
				class="paginate left">
			<i class="fas fa-chevron-left fa-fw" />
		</button>

		<button v-if="canGoNext"
				@click="next"
				type="button"
				class="paginate right">
			<i class="fas fa-chevron-right fa-fw" />
		</button>

		<div class="list"
			 :class="[! scrolling ? 'scroll-snap' : undefined]"
			 ref="carousel"
			 @scroll="onScroll">
			<div v-if="hasData(slug)"
				 v-for="slug in Object.keys(networks)"
				 class="network">
				<div class="title">
					<i :class="networks[slug].icon" />
					<span>{{ networks[slug].label }}</span>
				</div>

				<div class="figures">
					<div v-for="metric in metrics"
						 :key="`metric-${metric.label}`"
						 class="figure">
						<div class="meta">
							<div class="type">
								<i class="fa-fw icon" :class="metric.icon" />
								<span>{{ metric.label }}</span>
							</div>

							<div v-if="formatValue(slug, metric) !== null"
								 class="value">
								{{ formatValue(slug, metric) }}
							</div>

							<div v-else-if="! loaded" class="mock value">
								12345
							</div>

							<div v-else class="value">
								-
							</div>

							<template v-if="showComparison">
								<div v-if="diff(slug, metric) !== null"
									 class="change">
									<i v-if="diff(slug, metric).toFixed() > 0"
									   class="fas fa-caret-up fa-fw"
									/>

									<i v-else-if="diff(slug, metric).toFixed() < 0"
									   class="fas fa-caret-down fa-fw"
									/>

									<span>{{ Math.abs(diff(slug, metric)).toFixed() }} %</span>
								</div>

								<div v-else-if="! loaded" class="change mock">
									<span>X</span>
									<span>12%</span>
								</div>

								<div v-else class="change">
									<span>-</span>
								</div>
							</template>
						</div>
					</div>
				</div>

				<div v-if="slug === 'linnkedin'"
					 v-b-tooltip.hover
					 :title="$t('linkedIn.ads.aggregated.tooltip')"
					 class="aggregated">
					<i class="fas fa-info-circle fa-fw" />
					<span>{{ $t('linkedIn.ads.aggregated.label') }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
div.networks {
	position: relative;

	> button.paginate {
		position: absolute;
		top: 50%;

		width: 40px;
		height: 40px;
		border-radius: 50%;

		display: flex;
		align-items: center;
		justify-content: center;

		font-size: 16px;

		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

		border: 0;
		background-color: #fff;

		&.left {
			left: 0;
			transform: translateY(-50%) translateX(-50%);
		}

		&.right {
			right: 0;
			transform: translateY(-50%) translateX(50%);
		}
	}

	> div.list {
		display: flex;
		overflow-x: scroll;

		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;
		scrollbar-width: none;


		&.scroll-snap {
			scroll-snap-type: x mandatory;
			scroll-behavior: smooth;

			> div.network {
				scroll-snap-align: start;
				scroll-snap-stop: always;
			}
		}

		gap: 15px;

		> div.network {
			flex-basis: calc(33.3333% - 10px);
			flex-shrink: 0;

			> div.aggregated {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				padding: 15px 10px;
				border-top: 1px solid #eee;

				> i {
					font-size: 11px;
				}

				> span {
					color: #000;
					font-size: 15px;
					font-weight: 500;
				}
			}

			> div.title {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				padding: 20px;

				i {
					color: #274fd2;
					font-size: 26px;
				}

				span {
					color: #000;
					font-size: 20px;
					font-weight: 600;
				}
			}

			border: 1px solid #eee;
			border-radius: 8px;
			background-color: #fff;

			> div.figures {
				display: flex;
				flex-wrap: wrap;

				> div.figure {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 10px;

					padding: 15px;

					flex-basis: 50%;
					flex-shrink: 0;
					flex-grow: 0;
					border-top: 1px solid #eee;

					> div.icon {
						color: #274fd2;
						font-size: 20px;
					}

					> div.meta {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						line-height: 100%;
						gap: 15px;

						> div.type {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							gap: 6px;
							color: #79828d;
							font-weight: 500;
							font-size: 14px;
							letter-spacing: -.1px;

							> .icon {
								color: #274fd2;
								font-size: 125%;
							}

							i {
								font-size: 80%;
							}
						}

						> div.value {
							color: #262626;
							font-size: 18px;
							font-weight: 600;
						}

						> div.change {
							display: flex;
							align-items: center;
							gap: 2px;
							color: #727272;
							font-size: 13px;
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1500px) {
	div.networks {
		> div.list {
			> div.network {
				flex-basis: calc(50% - 7.5px);
			}
		}
	}
}

@media screen and (max-width: 945px) {
	div.networks {
		> div.list {
			> div.network {
				flex-basis: 100%;
			}
		}
	}
}
</style>

<script>
import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import marketingMixin from '@/mixins/marketingMixin'

import moment from 'moment'
import {mapGetters} from "vuex";
import {getTranslatedErrorMessage} from "@/services/response/ResponseService";

import LinkedInService from '@/services/linkedin/LinkedInService'
import LinkedInMetrics from "@/app/linkedin/pages/LinkedInMetrics";

export default {
	mixins: [
		datepickerMixin,
		marketingMixin
	],

	props: {
		networks: {
			type: Object,
			required: true
		},

		views: {
			type: Object,
			required: true
		},

		view: {
			type: String,
			required: true
		},

		compareTo: {
			type: String,
			required: false,
			default: 'year'
		},

		currency: {
			type: String,
			required: false
		}
	},

	data: () => ({
		demo: {},
		networkData: {},
		networkDataPrevious: {},
		loaded: false,

		offset: 0,
		scrolling: false,
		iterator: 0
	}),

	computed: {
		...mapGetters("customer", {
			customer: "getCustomer",
		}),

		...mapGetters('ghost', {
			isDemo: 'isDemo',
		}),

		showComparison() {
			return this.$store.getters['datepicker/getShowComparison']
		},

		daysBetween() {
			const duration = moment.duration(this.to.diff(this.from))
			return duration.asDays()
		},

		previousFrom() {
			switch (this.compareTo) {
				case 'period':
					return moment(this.from).subtract(this.daysBetween * 2, 'days')

				default:
					return moment(this.from).subtract(1, 'year')
			}
		},

		previousTo() {
			switch (this.compareTo) {
				case 'period':
					return moment(this.to).subtract(this.daysBetween * 2, 'days')

				default:
					return moment(this.to).subtract(1, 'year')
			}
		},

		metrics() {
			return this.views[this.view]
		},

		integrationId() {
			if (this.isDemo) {
				return 'demo-id'
			}

			const integrations = this.customer.integrations

			const match = integrations.find(integration => integration.service.name === 'LinkedIn')

			return !! match
				? match.id
				: null
		},

		canGoPrevious() {
			return this.offset > 20
		},

		canGoNext() {
			const iterator = this.iterator
			const wrapper = this.$refs.wrapper

			if (! wrapper) {
				return
			}

			return this.offset < this.elmScrollWidth() - wrapper.offsetWidth - 100
		}
	},

	watch: {
		from() {
			this.networkData = {}
			this.networkDataPrevious = {}

			this.onLoad()
		},

		compareTo() {
			this.networkData = {}
			this.networkDataPrevious = {}

			this.onLoad()
		}
	},

	mounted() {
		this.onLoad()

		window.addEventListener('resize', this.iterate)

		this.$nextTick(() => {
			this.iterate()
		})
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.iterate)
	},

	methods: {
		hasData(network) {
			return ! this.loaded || this.metrics.reduce(
				(carry, metric) => {
					return carry || !! this.formatValue(network, metric)
				},
				false
			)
		},

		formatValue(network, metric) {
			if (! this.networkData[network]) {
				return null
			}

			let value = null

			switch (metric.field) {
				case 'calculation':
					value = metric.calculation(this.networkData[network])
					break

				default:
					value = this.networkData[network][metric.field] || null
					break
			}

			if (! value) {
				return null
			}

			const type = metric.type

			switch (type) {
				case 'currency':
					return this.marketingFormat(
						Number(value),
						type,
						{ currency: this.currency || 'USD' }
					)

				default:
					return this.marketingFormat(
						value,
						type
					)
			}
		},

		diff(network, metric) {
			if (! this.networkData[network] ||
				! this.networkDataPrevious[network]
			) {
				return null
			}

			let value = null
			let valuePrevious = null

			value = this.networkData[network][metric.field]
			valuePrevious = this.networkData[network][metric.field]

			if (! valuePrevious) {
				return null
			}

			const diff = Number(value) - Number(valuePrevious)

			return diff / Number(value) * 100
		},

		onLoad() {
			this.loaded = false

			const promises = Object.keys(this.networks).reduce(
				(carry, slug) => {
					carry.push(new Promise((resolve) => {
						LinkedInService.insights(
							{
								pivot: 'ACCOUNT',
								timeGranularity: 'ALL',
								from: this.from.format('YYYY-MM-DD'),
								to: this.to.format('YYYY-MM-DD'),
								fields: LinkedInMetrics.join(',')
							},
							response => {
								this.$set(this.networkData, slug, response.data.elements[0])
								resolve()
							},
							response => {
								resolve()
							}
						)
					}))

					carry.push(new Promise((resolve) => {
						LinkedInService.insights(
							{
								pivot: 'ACCOUNT',
								timeGranularity: 'ALL',
								from: this.previousFrom.format('YYYY-MM-DD'),
								to: this.previousTo.format('YYYY-MM-DD'),
								fields: LinkedInMetrics.join(',')
							},
							response => {
								this.$set(this.networkDataPrevious, slug, response.data.elements[0])
								resolve()
							},
							response => {
								resolve()
							}
						)
					}))

					return carry
				},
				[]
			)

			Promise.all(promises).finally(() => {
				this.loaded = true

				this.$nextTick(() => {
					this.iterate()
				})
			})
		},

		elmWidth() {
			const carousel = this.$refs.carousel

			if (! carousel) {
				return null
			}

			return this.elmScrollWidth() / carousel.children.length
		},

		elmScrollWidth() {
			const carousel = this.$refs.carousel

			if (! carousel) {
				return null
			}

			return carousel.scrollWidth
		},

		visibleElements() {
			const wrapper = this.$refs.wrapper

			if (! wrapper) {
				return null
			}

			return Math.ceil(wrapper.offsetWidth / this.elmWidth())
		},

		scrollTo(offset) {
			this.offset = offset

			const carousel = this.$refs.carousel

			if (! carousel) {
				return null
			}

			this.scrolling = true

			carousel.scrollTo({
				left: this.offset,
				behavior: 'smooth'
			})
		},

		next() {
			if (this.scrolling) {
				return
			}

			const elmScrollWidth = this.elmScrollWidth()
			const elmWidth = this.elmWidth()

			if (! elmScrollWidth || ! elmWidth) {
				return
			}

			const scrollWidth = ! this.group
				? elmWidth
				: elmWidth * this.visibleElements()

			this.offset + scrollWidth <= elmScrollWidth - elmWidth
				? this.scrollTo(this.offset + scrollWidth)
				: this.scrollTo(elmScrollWidth - scrollWidth)
		},

		previous() {
			if (this.scrolling) {
				return
			}

			const elmWidth = this.elmWidth()

			if (! elmWidth) {
				return
			}

			const scrollWidth = ! this.group
				? elmWidth
				: elmWidth * this.visibleElements()

			this.offset - scrollWidth > 0
				? this.scrollTo(this.offset - scrollWidth)
				: this.scrollTo(0)
		},

		onScroll($event) {
			const offset = $event.target.scrollLeft

			if (this.scrolling) {
				if (this.offset - 2 < offset &&
					this.offset + 2 > offset
				) {
					this.scrolling = false
				}

				return
			}

			this.offset = offset

			this.iterate()
		},

		iterate() {
			this.iterator++
		}
	}
}
</script>
