<template>
	<div>
		<logo-loader v-if="!hasLoaded"/>
		<div class="bar-chart">
			<div class="chart"></div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.bar-chart {
	padding-top: 50px;
}
</style>

<script>
import datepickerMixin from '@/app/datepicker/datepicker.mixin'

import HighCharts from 'highcharts'
import LinkedInSocialService from "@/services/linkedin/LinkedInSocialService";

export default {
	mixins: [datepickerMixin],

	data () {
		return {
			chart: null,
			hasLoaded: false,
			metrics: []
		}
	},

	computed: {
		labels () {
			return this.metrics.map(metric => metric.label)
		}
	},

	mounted () {
		this.load()
	},

	methods: {
		load () {
			this.hasLoaded = false

			this.metrics = []

			const options = {
				from: this.from.format('YYYY-MM-DD'),
				to: this.to.format('YYYY-MM-DD')
			}

			LinkedInSocialService.pageInsights(options, response => {
				this.metrics = response.data

				this.createSeries()
			})
		},

		createSeries () {
			const items = ['impressions', 'likes', 'shares', 'comments', 'organicFollowers', 'paidFollowers']

			console.log('this far')

			const series = items.reduce(
				(carry, item) => {
					carry.push({
						name: item,
						data: this.metrics.map(metric => metric[item] || 0),
						marker: {
							symbol: 'circle',
							radius: 3,
							lineWidth: 2,
							lineColor: '#fff'
						}
					})

					return carry
				},
				[]
			)

			console.log({ series })

			this.render(series)
		},

		calculateMetricValue (metricValue) {
			const value = metricValue.value

			if (value instanceof Array) {
				return 0
			}

			if (!(value instanceof Object)) {
				return value
			}

			return Object.values(value).reduce((a, b) => a + b)
		},

		render (series) {
			if (this.chart) {
				this.chart.destroy()
			}

			const el = this.$el.querySelector('.chart')
			const height = 350

			this.hasLoaded = true

			let component = this

			this.chart = new HighCharts.Chart(el, {
				credits: false,
				chart: {
					type: 'area',
					stacking: 'normal',
					zoomType: 'x',
					height: height
				},
				title: {
					text: null
				},
				legend: {
					itemStyle: {
						'fontFamily': '"Roboto","Helvetica Neue",sans-serif',
						'fontWeight': 400
					},
					symbolRadius: 0,

					labelFormatter: function () {
						return component.translate(this.name)
					}
				},
				tooltip: {
					shared: true,
					formatter: function () {
						let total = 0
						let text = '<strong>' + this.x + '</strong><br />'

						this.points.forEach(function (point) {
							text += component.translate(point.series.name) + ': '
							text += point.y
							text += '<br />'
							total += point.y
						})

						return text
					}
				},

				plotOptions: {
					series: {
						stacking: 'normal'
					}
				},

				xAxis: {
					categories: this.labels
				},

				yAxis: {
					enabled: false,
					title: {
						text: null
					},
					allowDecimals: false
				},

				series
			})
		},

		translate (name) {
			const key = `linkedInSocial.chart.${name}`
			const translation = $t(key)

			return key !== translation ? translation : name
		},
	}
}
</script>
