const BaseService = require('@/services/BaseService')

const namespace = 'linked-in-social'

const page = (options, cb, errorCb) => {
    BaseService.get(`${namespace}/page`, options, cb, errorCb)
}

const logo = (options, cb, errorCb) => {
    BaseService.get(`${namespace}/logo`, options, cb, errorCb)
}

const pageStatistics = (options, cb, errorCb) => {
    BaseService.get(`${namespace}/page-statistics`, options, cb, errorCb)
}

const followerStatistics = (options, cb, errorCb) => {
    BaseService.get(`${namespace}/follower-statistics`, options, cb, errorCb)
}

const shareStatistics = (options, cb, errorCb) => {
    BaseService.get(`${namespace}/share-statistics`, options, cb, errorCb)
}

const posts = (options, cb, errorCb) => {
    BaseService.get(`${namespace}/posts`, options, cb, errorCb)
}

const postStatistics = (id, options, cb, errorCb) => {
    BaseService.get(`${namespace}/posts/${id}/statistics`, options, cb, errorCb)
}

const pageInsights = (options, cb, errorCb) => {
    BaseService.get(`${namespace}/page-insights`, options, cb, errorCb)
}

export default {
	page,
	logo,
    pageStatistics,
	followerStatistics,
	shareStatistics,
	posts,
	postStatistics,
	pageInsights
}
