<template>
	<div v-if="campaignGroup === null"
		 class="table-list">
		<div class="table-headlines">
			<div class="main">
				<div class="title">
					<span>{{ $t('linkedIn.ads.campaignGroups') }}</span>
				</div>
			</div>

			<div class="metrics">
				<div v-for="item in views[view]" class="metric">
					<i class="fa-fw" :class="item.icon" />
					<span>{{ item.label }}</span>
				</div>
			</div>
		</div>

		<div class="table-rows">
			<template v-if="items !== null"
					  v-for="(item, index) in filteredItems">
				<div @click="setCampaignGroup(item)"
					 class="table-row">
					<div class="main">
						<div class="name">{{ item.name }}</div>

						<div class="status-list">
							<div v-if="item.status === 'ACTIVE'" class="status success">
								<i class="fas fa-check fa-fw" />
								<span>{{ $t('linkedIn.ads.statuses.active') }}</span>
							</div>

							<div v-else class="status danger">
								<i class="fas fa-remove fa-fw" />
								<span>{{ $t('linkedIn.ads.statuses.inactive') }}</span>
							</div>
						</div>
					</div>

					<div class="metrics">
						<div v-for="viewItem in views[view]" class="metric">
							<div class="metric-label">
								<i class="fa-fw" :class="viewItem.icon" />
								<span>{{ viewItem.label }}</span>
							</div>

							<span v-if="formatValue(item, viewItem) !== null">{{ formatValue(item, viewItem) }}</span>
							<span v-else>-</span>
						</div>
					</div>
				</div>
			</template>

			<div v-if="items === null"
				 v-for="n in 8"
				 :key="`campaign-placeholder-${n}`"
				 class="table-row">
				<div class="mock main">
					<div class="name">Name here</div>
				</div>

				<div class="mock metrics">
					<div v-for="item in views[view]" class="metric">
						<div class="metric-label">
							<i class="fa-fw" :class="item.icon" />
							<span>{{ item.label }}</span>
						</div>

						<span>XYZ</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<campaigns v-else
			   :network="network"
			   :networks="networks"
			   :views="views"
			   :status="status"
			   :view="view"
			   :query="query"
			   :campaign-group="campaignGroup"
			   :campaign="campaign"
			   :ad="ad"
			   :currency="currency"
			   @campaignGroup="setCampaignGroup"
			   @campaign="setCampaign"
			   @ad="setAd"
	/>
</template>

<style lang="scss" scoped>
div.table-list {
	display: flex;
	flex-direction: column;
	border: 1px solid #eee;
	border-radius: 8px;
	background-color: #fff;

	.main {
		width: 320px;
	}

	> div.table-headlines {
		display: flex;
		align-items: center;
		min-height: 60px;
		gap: 20px;
		border-bottom: 1px solid #eee;

		color: #1f1f1f;
		font-size: 16px;
		font-weight: 600;

		.controls {
			display: flex;
			align-items: center;
			gap: 6px;

			margin-left: auto;

			.search {
				position: relative;
				border: 1px solid #ccc;
				border-radius: 6px;
				overflow: hidden;

				> div.icon {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 0 0 8px;
					position: absolute;
					pointer-events: none;
					touch-action: none;
					top: 0;
					bottom: 0;
					left: 0;
					font-size: 16px;
				}

				input {
					height: 36px;
					border: 0;
					outline: 0;
					box-shadow: none;
					padding: 0 14px 0 36px;
					line-height: 100%;
					font-size: 14px;
					font-weight: 400;
				}
			}
		}

		> div.main {
			display: flex;
			align-items: center;
			gap: 8px;
			padding: 15px 25px;

			> div.title {
				> span {
					color: #9f9f9f;
					font-weight: 400;

					&:after {
						color: #9f9f9f;
						display: inline-block;
						padding: 0 7px;
						content: '/';
					}

					&:last-child {
						color: #000;
						font-weight: 500;

						&:after {
							display: none;
						}
					}
				}
			}
		}

		> div.metrics {
			display: flex;
			flex: 1;

			> div.metric {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 4px;

				padding: 15px 25px;
				min-height: 100%;

				flex: 1 1 0;
				width: 0;

				i {
					font-size: 20px;
				}

				font-size: 12px;
				font-weight: 500;
			}
		}
	}

	> div.table-rows {
		display: flex;
		flex-direction: column;

		> div.table-row {
			display: flex;
			gap: 20px;
			border-top: 1px solid #dcdcdc;

			&:first-child {
				border-top: 0;
			}

			&:nth-child(odd) {
				background-color: #fafafa;
			}

			&:hover {
				cursor: pointer;
				user-select: none;
				background-color: #eee;
			}

			.main {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 6px;
				padding: 15px 25px;

				.name {
					color: #000;
					font-size: 13px;
					font-weight: 500;
				}

				.status-list {
					display: flex;
					align-items: center;
					gap: 6px;

					> div.status {
						display: flex;
						align-items: center;
						gap: 4px;
						line-height: 100%;

						color: #383838;
						font-size: 11px;
						font-weight: 500;
						letter-spacing: -.1px;
						padding: 4px 5px;
						border-radius: 4px;
						background-color: #eee;

						i {
							margin-top: 1px;
						}

						&.success {
							color: #fff;
							background-color: #73af6e;
						}

						&.warning {
							color: #383838;
							background-color: #eee16e;
						}

						&.danger {
							color: #fff;
							background-color: #cc6f6f;
						}
					}
				}
			}

			> div.metrics {
				display: flex;
				flex: 1;

				> div.metric {
					flex: 1 1 0;
					width: 0;

					padding: 15px 25px;
					min-height: 100%;

					display: flex;
					align-items: center;
					justify-content: center;

					.metric-label {
						display: none;
					}

					&:nth-child(odd) {
						background-color: rgba(0, 0, 0, .02);
					}

					> span {
						padding: 4px 4px;
						line-height: 100%;
						font-size: 13px;
						font-weight: 500;
					}
				}
			}
		}

		> div.table-sub-headlines {
			display: flex;
			align-items: center;
			min-height: 50px;
			gap: 20px;

			border-top: 1px solid #eee;
			border-bottom: 1px solid #eee;

			color: #1f1f1f;
			font-size: 13px;
			font-weight: 600;

			background-color: #fff;

			> div.main {
				display: flex;
				align-items: center;
				gap: 8px;
				padding: 6px 25px 6px 60px;

				> div.title {
					> span {
						color: #000;
						font-weight: 500;
					}
				}
			}

			> div.metrics {
				display: flex;
				flex: 1;

				> div.metric {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					gap: 4px;

					padding: 6px 25px;
					min-height: 100%;

					flex: 1 1 0;
					width: 0;

					i {
						font-size: 14px;
					}

					font-size: 11px;
					font-weight: 500;
				}
			}
		}

		> div.table-sub-row {
			display: flex;
			gap: 20px;
			border-bottom: 1px solid #eee;

			&:nth-child(odd) {
				background-color: #fafafa;
			}

			&:hover {
				cursor: pointer;
				user-select: none;
				background-color: #eee;
			}

			.main {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 6px;
				padding: 15px 25px 15px 60px;

				.name {
					color: #565656;
					font-size: 13px;
					font-weight: 400;
				}

				.status-list {
					display: flex;
					align-items: center;
					gap: 6px;

					> div.status {
						display: flex;
						align-items: center;
						gap: 4px;
						line-height: 100%;

						color: #383838;
						font-size: 11px;
						font-weight: 500;
						letter-spacing: -.1px;
						padding: 4px 5px;
						border-radius: 4px;
						background-color: #eee;

						i {
							margin-top: 1px;
						}

						&.success {
							color: #fff;
							background-color: #73af6e;
						}

						&.warning {
							color: #383838;
							background-color: #eee16e;
						}

						&.danger {
							color: #fff;
							background-color: #cc6f6f;
						}
					}
				}
			}

			> div.metrics {
				display: flex;
				flex: 1;

				> div.metric {
					flex: 1 1 0;
					width: 0;

					padding: 15px 25px;
					min-height: 100%;

					display: flex;
					align-items: center;
					justify-content: center;

					.metric-label {
						display: none;
					}

					&:nth-child(odd) {
						background-color: rgba(0, 0, 0, .02);
					}

					> span {
						padding: 4px 4px;
						line-height: 100%;
						color: #656565;
						font-size: 13px;
						font-weight: 500;
					}

					&.secondary {
						> span {
							color: #a1a1a1;
							font-weight: 400;
						}
					}
				}
			}
		}
	}

	> div.table-pagination {
		padding: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 15px;

		> button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			color: #000;
			font-size: 15px;
			border: 2px solid #ccc;
			background-color: #fff;

			&:disabled {
				opacity: .5;
			}

			&:hover {
				color: #fff;
				border-color: #000;
				background-color: #000;
			}
		}
	}
}

@media(max-width: 750px) {
	div.table-list {
		.main {
			width: 100%;
		}

		> div.table-headlines {
			> div.main {
				padding: 15px !important;
			}

			> div.metrics {
				display: none;
			}
		}

		> div.table-rows {
			> div.table-row {
				display: flex;
				flex-direction: column;
				padding: 0 !important;

				> div.main {
					flex: 0;
					padding: 15px 15px 0 15px !important;
				}

				> div.metrics {
					flex: 0;

					display: flex;
					flex-direction: column;
					align-items: flex-start;

					> div.metric {
						display: flex;
						width: 100%;
						justify-content: space-between;
						border-bottom: 1px solid #eee;
						padding: 25px 15px !important;

						&:last-child {
							border-bottom: 0;
						}
					}

					.metric-label {
						display: flex !important;
						align-items: center;
						gap: 6px;

						font-weight: 500;

						i {
							color: #274fd2;
						}
					}
				}
			}

			> div.table-sub-headlines {
				> div.main {
					padding: 15px 15px 15px 40px !important;
				}

				> div.metrics {
					display: none;
				}
			}

			> div.table-sub-row {
				display: flex;
				flex-direction: column;
				padding: 0 !important;

				> div.main {
					flex: 0;
					padding: 15px 15px 0 40px !important;
				}

				> div.metrics {
					flex: 0;

					display: flex;
					flex-direction: column;
					align-items: flex-start;

					> div.metric {
						display: flex;
						width: 100%;
						justify-content: space-between;
						border-bottom: 1px solid #eee;
						padding: 25px 15px 25px 40px !important;

						&:last-child {
							border-bottom: 0;
						}
					}

					.metric-label {
						display: flex !important;
						align-items: center;
						gap: 6px;

						font-weight: 500;

						i {
							color: #274fd2;
						}
					}
				}
			}
		}
	}
}
</style>

<script>
import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import marketingMixin from '@/mixins/marketingMixin'

import moment from 'moment'
import Campaigns from './Campaigns'
import {mapGetters} from "vuex";

import LinkedInService from '@/services/linkedin/LinkedInService'
import LinkedInMetrics from "@/app/linkedin/pages/LinkedInMetrics";

export default {
	mixins: [
		datepickerMixin,
		marketingMixin
	],

	props: {
		network: {
			type: String,
			required: false,
			default: 'linkedin'
		},

		conversionActions: {
			type: Array,
			required: false,
			default: null
		},

		networks: {
			type: Object
		},

		views: {
			type: Object,
			required: true
		},

		status: {
			type: String,
			required: true
		},

		view: {
			type: String,
			required: true
		},

		campaignGroup: {
			type: Object,
			required: false,
			default: null
		},

		campaign: {
			type: Object,
			required: false,
			default: null
		},

		ad: {
			type: Object,
			required: false,
			default: null
		},

		query: {
			type: String,
			required: false,
			default: ''
		},

		currency: {
			type: String,
			required: false,
			default: null
		}
	},

	data: () => ({
		items: null,
		metrics: {},
		page: 1,
		limit: 50,
		total: null
	}),

	computed: {
		...mapGetters("customer", {
			customer: "getCustomer",
		}),

		...mapGetters('ghost', {
			isDemo: 'isDemo',
		}),

		filteredItems() {
			const query = String(this.query || '').toLowerCase()

			if (query.length === 0) {
				return this.items
			}

			return this.items.filter(item => String(item.campaign.name || '').toLowerCase().includes(query))
		},

		integrationId() {
			if (this.isDemo) {
				return 'demo-id'
			}

			const integrations = this.customer.integrations

			const match = integrations.find(integration => integration.service.name === "LinkedIn")

			return !! match
				? match.id
				: null
		},

		orderBy() {
			return this.views[this.view].reduce(
				(carry, item) => {
					if (!! carry || item.source !== 'metrics') {
						return carry
					}

					return `metrics.${item.field} DESC`
				},
				null
			)
		}
	},

	watch: {
		from() {
			this.reset()
			this.onLoad()
		},

		status() {
			this.reset()
			this.onLoad()
		},

		network() {
			this.onLoad()
		},

		orderBy() {
			this.onLoad()
		}
	},

	mounted() {
		this.onLoad()
	},

	methods: {
		reset() {
			this.page = 1
			this.total = null
			this.items = null
		},

		onLoad() {
			this.items = null

			LinkedInService.campaignGroups(
				{
					from: this.from.format('YYYY-MM-DD'),
					to: this.to.format('YYYY-MM-DD'),
					status: this.status,
					page: this.page,
					limit: this.limit
				},
				(response) => {
					const items = response.data.elements
					this.items = response.data.elements

					items.forEach(item => {
						LinkedInService.insights({
							pivot: 'CAMPAIGN_GROUP',
							timeGranularity: 'ALL',
							from: this.from.format('YYYY-MM-DD'),
							to: this.to.format('YYYY-MM-DD'),
							fields: LinkedInMetrics.join(','),
							campaignGroup: item.id
						}, response => {
							this.$set(this.metrics, item.id, response.data.elements[0])
						}, response => {
						})
					})
				},
				(res) => {
					// @TODO: Error
				}
			)
		},

		formatValue(item, metric) {
			if (! this.metrics[item.id]) {
				return null
			}

			let value = null

			switch (metric.field) {
				case 'calculation':
					value = metric.calculation(this.metrics[item.id])
					break

				default:
					value = this.metrics[item.id][metric.field] || null
					break
			}

			if (! value) {
				return null
			}

			const type = metric.type

			switch (type) {
				case 'currency':
					return this.marketingFormat(
						Number(value),
						type,
						{ currency: this.currency || 'USD' }
					)

				default:
					return this.marketingFormat(
						value,
						type
					)
			}
		},

		setCampaignGroup(campaignGroup) {
			this.$emit('campaignGroup', campaignGroup)
		},

		setCampaign(campaign) {
			this.$emit('campaign', campaign)
		},

		setAd(ad) {
			this.$emit('ad', ad)
		},

		formatDate(date) {
			return moment(date).format('ll')
		},

		resolveConversions(id) {
			return (this.conversions || []).filter(
				item => item.campaign.id === id
			)
		},

		hasDate(date) {
			const oneYear = moment().add(1, 'year')

			return !! date && moment(date).isBefore(oneYear)
		}
	},

	components: {
		Campaigns
	},
}
</script>
