<template>
    <div class="linked-in-social">
        <div class="container-fluid">
            <check-for-missing-integrations :integrations="['LinkedIn Social']">
                <div slot="no-integrations">
                    <landing-page image="/images/integrations/cta-messages/linked-in-social.png"
                                  :headline="$t('linkedInSocial.noIntegration.headline')"
                                  :description="$t('linkedInSocial.noIntegration.description')">
                        <hr>

                        <integration name="LinkedIn Social"
                                    service="linked-in-social"
                                    :border="true"
                                     :logos="['/images/onboarding/linkedin.svg']"
                                     :headline="$t('integrations.list.linkedInSocial.headline')"
                                     :description="$t('integrations.list.linkedInSocial.description')"
						/>
                    </landing-page>
                </div>

				<div class="row flex-parent">
					<div class="col-lg-8 col-between-lg-md col-md-12 col-sm-12 col-xs-12 flex-child--full">
						<card icon="pe-7s-graph3"
							:headline="$t('linkedInSocial.historical.headline')"
							:description="$t('linkedInSocial.historical.description')">
							<div class="chart-container">
								<page-line-chart />
							</div>
						</card>
					</div>

					<div class="col-lg-4 col-between-lg-md col-md-12 col-sm-12 col-xs-12">
						<card icon="pe-7s-note"
							:headline="$t('linkedInSocial.pageStatistics.headline')"
							:description="$t('linkedInSocial.pageStatistics.description')">
							<template v-if="pageStatistics">
								<template v-if="!! followers">
									<div class="pageStatisticsSubject">
										<strong>{{$t('linkedInSocial.organicFollowers.title')}}</strong>
									</div>

									<div class="pageStatisticsContent" v-html="$t('linkedInSocial.organicFollowers.text', {value: followers.organic})" />

									<br />
									<br />
									<br />

									<div class="pageStatisticsSubject">
										<strong>{{$t('linkedInSocial.paidFollowers.title')}}</strong>
									</div>

									<div class="pageStatisticsContent" v-html="$t('linkedInSocial.paidFollowers.text', {value: followers.paid})" />

									<br />
									<br />
								</template>
							</template>

							<div class="text-center" v-else>
								<span class="fa fa-circle-o-notch fa-fw fa-spin" />
							</div>

							<div class="clearfix" />
						</card>
					</div>
				</div>

				<div class="row flex-parent">
					<div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 flex-child--full post-between-lg-md">
						<div style="width: 100%;">
							<card ref="post"
								style="width: 100%; position: sticky; top: 75px;"
								class="linked-in-post"
								icon="pe-7s-news-paper"
								:headline="$t('linkedInSocial.specificPost.headline')"
								:description="$t('linkedInSocial.specificPost.description')">
								<linked-in-post v-if="currentPost"
												:post="currentPost"
												:page-info="pageInfo"
												:logo="logo"
												:followers="followersTotal"
								/>

								<div class="text-center" v-else>
									<span class="fa fa-circle-o-notch fa-fw fa-spin" />
								</div>
							</card>
						</div>
					</div>

					<div class="col-lg-8 col-md-6 col-md-12 col-sm-12 col-xs-12 post-container-between-lg-md">
						<card icon="pe-7s-albums" :headline="$t('linkedInSocial.overview.headline')" :description="$t('linkedInSocial.overview.description')">
							<div class="row">
								<template v-if="hasPosts">
									<div v-for="post in posts"
										class="col-lg-3 col-md-6 col-sm-4 col-xs-6 posts-between-lg-md post-quickview"
										:class="{ 'selected-post': post && post.id === currentPost.id }"
										@click="setCurrentPost(post)">

										<div class="image-container"
											 :style="{ backgroundImage: `url(${image(post)})` }"
										/>

										<small>{{post.text.text | substr(0, 100)}} ...</small>
									</div>
								</template>

								<infinite-loading @infinite="loadPosts($event)"
												  v-if="hasPosts"
												  :distance="250">
									<span slot="no-results" />
									<span slot="no-more" />
								</infinite-loading>
							</div>
						</card>
					</div>
				</div>
            </check-for-missing-integrations>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_mixins.scss';

    @media screen and (min-width: 1200px) and (max-width: 1500px) {
        .post-between-lg-md, .posts-between-lg-md, .post-container-between-lg-md {
            width: 50% !important;
        }
    }

    @media screen and (min-width: 992px) and (max-width: 1100px) {
        .post-between-lg-md, .post-container-between-lg-md {
            width: 100% !important;
        }

        .posts-between-lg-md {
            width: 25% !important;
        }
    }

    .chart-container {
        overflow: hidden;
        height: 380px;
    }

    .pageStatisticsSubject {
        padding: 0 15px 5px 15px;
        float: left;
        width: 140px;
    }

    .pageStatisticsContent {
        width: calc(100% - 140px);
        padding: 0 15px 5px 15px;
        float: left;
    }

    .post-placeholder {
        height: 237px;
        padding: 20px;
        .post-placeholder__img {
            width: 100%;
            height: 116px;
            background: #f9f9f9;
        }
        .post-placeholder__text {
            width: 100%;
            height: 55px;
            border: 1px solid #f9f9f9;
            .text {
                height: 5px;
                background: #f9f9f9;
                margin: 10px 0 0 10px;
            }
        }
    }

    .flex-parent {
        display: flex;
        flex-flow: row wrap;
    }

    .flex-child--half {
        display: flex;
        flex: 1;
    }

    .flex-child--full {
        display: flex;
    }

    .post-quickview {
        overflow: hidden;
        padding: 20px;
        height: 200px;
        line-height: 14px;
        transition: all 0.6s ease-out;
        &:not(.selected-post) {
            background: #fefefe;
            border: 1px solid #fafafa;
            cursor: pointer;
        }
        .image-container {
            width: 100%;
            margin-bottom: 5px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            &:before {
                content: '';
                display: block;
                padding-top: 56.25%;
            }
        }
        small {
            color: #888;
            font-size: 10px;
        }
    }

    .selected-post {
        background: #f9f9f9;
        border: 1px solid #f0f0f0;
    }

    @media screen and(max-width: 1670px) and (min-width: 1200px) {
        .col-between-lg-md {
            width: 50%;
        }
        .pageStatisticsSubject {
            width: 100%;
        }
        .pageStatisticsContent {
            width: 100%;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #f9f9f9;
        }
    }
</style>

<script>
    import ReAuthenticatableIntegration from '@/app/integrations/components/scopes/ReAuthenticatableIntegration'
    import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
    import FacebookPagesService from '@/services/facebook/FacebookPagesService'
    import Integration from '@/app/onboarding/components/integrations/Integration'
    import PageLineChart from '@/app/linkedin/components/social/PageLineChart'
    import LandingPage from '@/app/onboarding/components/LandingPage'
    import LinkedInPost from '@/app/linkedin/components/social/LinkedInPost'
    import InfiniteLoading from 'vue-infinite-loading'
    import Card from '@/app/shared/components/Card'

    import {mapGetters} from 'vuex'

    import hasIntegration from '@/mixins/integrations/hasIntegration'
    import organization from '@/mixins/customer/organization'
	import LinkedInSocialService from "@/services/linkedin/LinkedInSocialService";

    export default {
        data() {
            return {
				pageInfo: null,
				logo: null,
                posts: [],
                pageStatistics: null,
                followerStatistics: null,
                currentPostKey: null,
                limit: 12,
                page: 0
            }
        },

        mixins: [organization, hasIntegration],

        watch: {
            posts() {
                if (!this.posts.length) {
                    this.currentPostKey = null
                    return
                }

                if (this.currentPostKey === null) {
                    this.currentPostKey = 0
                }
            },

            identity() {
                if (! this.hasHealthyIntegration('LinkedIn Social')) {
                    return
                }

                this.loadPage()
                this.loadPosts()
                this.loadPageStatistics()
            }
        },

        computed: {
			...mapGetters('identity', {
			  identity: 'getIdentity'
			}),

			...mapGetters('customer', {
			  customer: 'getCustomer',
			}),

            hasPosts() {
                return this.posts.length > 0
            },

			followers() {
				if (! this.followerStatistics ||
					! this.followerStatistics.elements[0]
				) {
					return null
				}

				return this.followerStatistics.elements[0].followerCountsByCountry
					.reduce(
						(carry, item) => ({
							organic: carry.organic + Number(item.followerCounts.organicFollowerCount),
							paid: carry.paid + Number(item.followerCounts.paidFollowerCount),
						}),
						{organic: 0, paid: 0}
					)
			},

			followersTotal() {
				if (! this.followers) {
					return 0
				}

				return this.followers.organic + this.followers.paid
			},

            currentPost() {
                if (this.currentPostKey === null || !this.posts[this.currentPostKey]) {
                    return null
                }

                return this.posts[this.currentPostKey]
            },

            informationHasLoaded() {
                if (!this.hasPosts) {
                    return false
                }

                const information = Object.values(this.posts[this.currentPostKey].information).map(information => information.loaded)

                return !information.includes(false)
            },

            account() {
                const customer = this.customer
                const oauth2Accounts = customer.oauth2Accounts

                const account = oauth2Accounts.find((account) => {
                    return account.provider === 'linkedInSocial'
                })

                return account === undefined ? null : account
            }
        },

        mounted() {
            if (!this.hasHealthyIntegration('LinkedIn Social')) {
                return
            }

            this.loadPage()
            this.loadPosts()
            this.loadPageStatistics()

            eventHub.$on('linkedin.social.post.next', this.next)
            eventHub.$on('linkedin.social.post.previous', this.previous)
        },

        destroyed() {
            eventHub.$off('linkedin.social.post.next', this.next)
            eventHub.$off('linkedin.social.post.previous', this.previous)
        },

        methods: {
            loadPage() {
                this.pageInfo = null
                this.logo = null

				LinkedInSocialService.page({}, response => {
					this.pageInfo = response.data
				})

				LinkedInSocialService.logo({}, response => {
					this.logo = response.data
				})
            },

            loadPageStatistics() {
                this.pageStatistics = null
				this.followerStatistics = null

				LinkedInSocialService.pageStatistics({}, response => {
					this.pageStatistics = response.data
				})

				LinkedInSocialService.followerStatistics({}, response => {
					this.followerStatistics = response.data
				})
            },

            loadPosts($state) {
				const options = {
					count: this.limit,
					start: this.limit * this.page
				}

                LinkedInSocialService.posts(options, response => {
                    const data = response.data
                    const posts = data.elements

                    if (posts.length < 1) {
                        $state.complete()
                        return
                    }

                    this.posts.push(...posts)
                    this.page++

					posts.length < this.limit
						? $state.complete()
						: $state.loaded()
                })
            },

            setCurrentPost(post) {
                const posts = this.posts

                for(let i = 0; i < posts.length; i++) {
                    if (posts[i].id === post.id) {
                        this.currentPostKey = i
                        return
                    }
                }
            },

            next() {
                if (this.currentPostKey >= this.posts.length - 1) {
                    this.currentPostKey = 0
                    return
                }

                this.currentPostKey++
            },

            previous() {
                if (this.currentPostKey <= 0) {
                    this.currentPostKey = this.posts.length - 1
                    return
                }

                this.currentPostKey--
            },

			image(post) {
				return post.content.contentEntities.reduce(
					(carry, item) => {
						const thumbnails = item.thumbnails || []

						if (carry || ! thumbnails) {
							return carry
						}

						return thumbnails[0]
							? thumbnails[0].resolvedUrl
							: carry
					},
					null
				)
			}
        },

        components: {
            LandingPage,
            CheckForMissingIntegrations,
            Card,
			LinkedInPost,
            PageLineChart,
            InfiniteLoading,
            Integration,
            ReAuthenticatableIntegration
        }
    }
</script>
