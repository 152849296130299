export default [
	'actionClicks',
	'adUnitClicks',
	'cardImpressions',
	'clicks',
	'companyPageClicks',
	'conversionValueInLocalCurrency',
	'costInLocalCurrency',
	'externalWebsiteConversions',
	'impressions',
	'landingPageClicks',
	'reactions',

	'approximateMemberReach',

	// Video

	'videoStarts',
	'videoThirdQuartileCompletions',
	'videoViews',
	'videoCompletions',
	'videoViews',

	// Social

	'comments',
	'likes',
	'shares',
]
