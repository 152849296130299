<template>
    <div class="container-fluid linked-in-social">
        <div class="row" v-if="post">
			<div class="post-wrapper">
				<div class="user">
					<div class="image"
						 :style="{ backgroundImage: `url(${logo})` }"
					/>

					<div class="meta">
						<div class="name">{{ pageInfo.localizedName }}</div>
						<div class="followers">{{ $tc('linkedInSocial.followers', followers, { number: followers }) }}</div>
						<div class="date">{{ created | moment('from', 'now') }}</div>
					</div>
				</div>

				<div class="text">
					{{ post.text.text }}
				</div>

				<div v-if="!! image"
					 class="post-image"
					 :style="{
						backgroundImage: `url(${image.resolvedUrl})`,
						paddingBottom: `${image.imageSpecificContent.height /image.imageSpecificContent.width * 100}%`
					 }"
				/>

				<div class="content-info">
					<div class="content-title">{{ post.content.title }}</div>

					<div v-if="!! post.content.contentEntities[0] && !! post.content.contentEntities[0].entityLocation"
						 class="content-link">
						{{ post.content.contentEntities[0].entityLocation }}
					</div>
				</div>

				<a :href="`https://www.linkedin.com/embed/feed/update/${post.activity}`"
				   target="_blank">
					<button type="button" class="btn btn-primary btn-sm">{{$t('linkedInSocial.specificPost.seeOnLinkedIn')}}</button>
				</a>

                <div class="control-circle next" @click="next" :style="organizationBackgroundColor">
                    <i class="fa fa-arrow-right"></i>
                </div>

                <div class="control-circle previous" @click="previous" :style="organizationBackgroundColor">
                    <i class="fa fa-arrow-left"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .post-wrapper {
		.user {
			display: flex;
			align-items: center;

			div.image {
				width: 48px;
				height: 48px;
				background-color: #eee;
				border-radius: 50%;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
			}

			div.meta {
				flex: 1;
				margin-left: 10px;

				.name {
					color: rgba(0, 0, 0, .9);
					font-size: 14px;
					line-height: 20px;
					font-weight: 600;
				}

				.followers,
				.date {
					color: rgba(0, 0, 0, .6);
					font-size: 12px;
					line-height: 16px;
					font-weight: 400;
				}
			}
		}

		div.text {
			color: rgba(0, 0, 0, .9);
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			word-break: break-word;
			margin: 10px 0;
		}

		> div.post-image {
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-color: #eee;
		}

		> div.content-info {
			padding: 10px;
			background-color: #eef3f7;

			> div.content-title {
				color: rgba(0, 0, 0, .9);
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
			}

			> div.content-link {
				color: rgba(0, 0, 0, .6);
				font-size: 12px;
				font-weight: 400;
				line-height: 16px;
			}
		}
	}

    .control-circle {
        width: 160px;
        height: 40px;
        position: absolute;
        top: 210px;
        right: 0;
        cursor: pointer;
        background: #FFA534;
        color: #fff;

        &.previous {
            width: 40px;
            height: 40px;
            left: 0;
        }

        &.next {
            width: 40px;
            height: 40px;
            right: 0;
        }

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    @media screen and(max-width: 1670px) {
        .reaction__icon {
            width: 20px;
            height: 20px;
            background-size: contain !important;
            display: inline-block;
            float: left;
            margin-top: 10px !important;
            margin-right: 0 !important;
        }
    }

    @media screen and(max-width: 768px) {
        .linked-in-social {
            padding-bottom: 60px;
        }
        .reaction-hover {
            text-align: center;
        }
        .reaction__value {
            width: 100%;
            display: block;
        }
    }
</style>

<script>
	import moment from 'moment'

    export default {
        props: {
            post: Object,
			pageInfo: {
				type: Object,
				required: false,
				default: () => ({})
			},
			logo: {
				type: String,
				required: false,
				default: null
			},
			followers: {
				type: Number,
				required: false,
				default: 0
			}
        },

        data() {
            return {
                windowWidth: window.innerWidth
            }
        },

        mounted() {
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth
            })
        },

        destroyed() {
            window.removeEventListener('resize', () => {
                this.windowWidth = window.innerWidth
            })
        },

        computed: {
            mobileActive() {
                return this.windowWidth < 768
            },

            organizationBackgroundColor() {
                return {backgroundColor:$org('colors.standard.primary.hex')}
            },

            image() {
				const post = this.post

				if (! post) {
					return null
				}

				return post.content.contentEntities.reduce(
					(carry, item) => {
						const thumbnails = item.thumbnails || []

						if (carry || ! thumbnails) {
							return carry
						}

						return thumbnails[0]
							? thumbnails[0]
							: carry
					},
					null
				)
            },

			created() {
				return this.post.created.time
				return moment.unix(this.post.created.time).fromNow(false)
			}
        },

        methods: {
            next() {
                eventHub.$emit('linkedin.social.post.next')
            },

            previous() {
                eventHub.$emit('linkedin.social.post.previous')
            }
        }
    }
</script>
